import { Bar, mixins } from "vue-chartjs";

export default {
   extends: Bar,
   mixins: [ mixins.reactiveProp ],
   props: [ "chartData" ],
   data() {
      return {
         options: {
         //Chart.js options
            tooltips: {
               callbacks: {
                  label: function(tooltipItem, data) {
                     let fieldName = data.datasets[0].label
                     let value = Number(tooltipItem.value)

                     if(fieldName == 'Meta') {
                        return `${fieldName} ${Math.round(value)}%`
                     }

                     let values = value.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL"
                     });

                     return `${fieldName} ${values} `
                  }
               }
            },
            scales: {
               yAxes: [
                  {
                     ticks: {
                        beginAtZero: true,
                        fontSize: 10
                     },
                     gridLines: {
                        display: true
                     }
                  }
               ],
               xAxes: [
                  {
                     ticks: {
                        fontSize: 8
                     },
                     gridLines: {
                        display: false
                     }
                  }
               ]
            },
            legend: {
               display: false
            },
            responsive: true,
            maintainAspectRatio: false
         }
      };
   },
   mounted() {
      this.renderChart(this.chartdata, this.options);
   }
};
